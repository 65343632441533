import React, {useEffect, useRef} from "react";
import "./review.scss";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import Digitrame from "../../assets/img/Digitrame - logo fond bleu HD.png";
import scarab from '../../assets/img/logo-scarab-agency-700px.png'
import sport from '../../assets/img/Copie de Copie de logo SS.png'
import squarly from '../../assets/img/squarly.png'
import headsapp from '../../assets/img/headsapp.png'
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";


// import required modules
import {Navigation, Pagination} from "swiper";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import CardReview from "../../component/cardReview/CardReview";
import TitleSection from "../titleSection/TitlteSection";


const Review = () => {
    const {t, i18n} = useTranslation();

    gsap.registerPlugin(ScrollTrigger);
    const reviewRef = useRef(null);
    const DataReviews = [
        {
            comment:
                t('home.review.review_1'),
            img: Digitrame,
            name: "Pascal Damiani",
            job: "CEO",
            site: 'www.digitrame.fr',
            sub_title:'Digitrame, application de gestion commerciale 100% mobile'
        },
        {
            comment:
                t('home.review.review_2'),
            img: scarab,
            name: "Slim BOUSLAMA",
            job: "Founder and Chief Creative Officer",
            site: 'www.scara-b.agency'
        },
        {
            comment:
                t('home.review.review_3'),
            img: sport,
            name: "Khaled Chiboub",
            job: "CEO",
            site: 'www.sportsense.agency'
        },
        {
            comment:
                t('home.review.review_4'),
            img: headsapp,
            name: "Lotfi BENMOSBAH",
            job: "CEO & Co-Founder",
            site: 'www.headsapp.com'
        },
        {
            comment:
                t('home.review.review_5'),
            img: squarly,
            name: "Fatema ALATTAR",
            job: "Product Manager",
            site: 'sustainablesquare.com'
        },

    ];
    useEffect(() => {
        gsap.fromTo(
            ".title-review",
            {
                y: -50,
                opacity: 0,
            },
            {
                y: 0,
                delay: 0.5,
                opacity: 1,
                duration: 0.5,
                scrollTrigger: {
                    trigger: ".title-review",
                    start: "top 50%",
                },
            }
        );
        gsap.fromTo(
            ".swiper-rev",
            {
                y: 250,
                opacity: 0,
            },
            {
                delay: 0.5,
                y: 0,
                opacity: 1,
                duration: 0.5,
                scrollTrigger: {
                    trigger: "#cards-rev",
                    start: "top 50%",
                },
            }
        );
    }, []);

    return (
        <div className="reviews" ref={reviewRef}>
            <div className="title-review center-horizontally justify-center">
                <TitleSection title={t('review.title')} firstTitle={''}/>

            </div>
            <div className="blc-textt">
                <p style={{
                    textAlign: 'justify',
                    maxWidth: window.innerWidth > 768 ? '64.156vw' : '',
                    position: "relative",
                    left: window.innerWidth > 768 ? '18vw' : '',
                    bottom: window.innerWidth > 768 ? '3vw' : '',
                    padding: '20px',
                    margin: 'unset'

                }} id={'p'}>
                    {
                        t('review.description')
                    }

                </p>
                <p style={{
                    margin: 'unset',
                    textAlign: 'justify',
                    maxWidth: window.innerWidth > 768 ? '64.156vw' : '',
                    position: "relative",
                    left: window.innerWidth > 768 ? '18vw' : '',
                    bottom: window.innerWidth > 768 ? '3vw' : '',
                    paddingLeft: '20px',
                    fontSize: window.innerWidth > 768 ? '1vw' : '3.23vw',

                }} id={'p'}>
                    {
                        t('review.description2')
                    }
                </p>


            </div>

            <div className="blc-cards" id="cards-rev">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    // slidesPerView={window.innerWidth > 769   ? 2 : 1}
                    // slidesPerGroup={window.innerWidth > 769   ? 2 : 1}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    navigation
                    scrollbar={{draggable: true}}
                    className="mySwiper swiper-rev"
                   effect={"slide"}
                   autoplay={true}
                    loop={true}
                   allowSlideNext={true}
                   allowSlidePrev={true}
                   allowTouchMove={true}


                >
                    {DataReviews.map((item) => {
                        return (
                            <SwiperSlide >
                                <CardReview
                                    img={item.img}
                                    job={item.job}
                                    name={item.name}
                                    subTitle={item.sub_title}
                                    comment={item.comment}
                                    site={item.site}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default Review;
