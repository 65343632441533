import React from "react";
import '../maincomponent/mainComponent.css'
import TitleSection from "../titleSection/TitlteSection";
import '../../assets/i18n'
import aboutus from '../../assets/img/About_us.png'
import {useTranslation} from "react-i18next";

const AboutUsSection=()=>{
    const {t}=useTranslation()
    return(
        <section id={'propos'} className={'section'} style={{paddingTop: window.innerWidth > 768 ? '4vh' :'0vh',padding:'20px'}}>
            <div id={'second'} className="container reveal fade-left">
                <img className={'image1'}
                     src={aboutus}
                     alt={'image'}/>
                <div className="left-sect">
                    <div id="title-client">
                        <TitleSection title={t('aboutSection.propos')} firstTitle={''}/>
                    </div>

                    <div className="descript"><p style={{textAlign:'justify'}} id={'p'}>
                        {t('aboutSection.description')}
                    </p></div>
                </div>
            </div>
        </section>

    )
}
export default AboutUsSection
