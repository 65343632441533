import React, {useEffect} from 'react';
import './App.css';
import MainComponent from "./components/maincomponent/MainComponent";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Contact from "./component/contact/Contact";
import Career from "./component/career/Career";
import OffreDetails from "./component/career/OffreDetails";
import Intern from "./component/career/Intern";
import Jobs from "./component/career/Jobs";
import AboutUs from "./component/aboutus/AboutUs";
import i18n from "i18next";
// @ts-ignore
import {Helmet} from 'react-helmet'



function App() {
    useEffect(()=>{
        const lng = localStorage.getItem('language')
        i18n.changeLanguage(lng ? lng : 'fr');
    },[])
  return (
    <div className="App">
         <Helmet>
             <meta charSet="utf-8"/>
             <meta
                 name="description"
                 content="Quel que soit votre secteur d'activité, votre besoin ou votre objectif, nos équipes vous proposeront des solutions innovantes et 100% sur-mesure en adéquation avec votre métier. Nous vous accompagnerons dans la digitalisation et l'automatisation de vos processus en traduisant vos besoins en solutions web et mobile innovantes"
             />
             <meta name="keywords" content="web, mobile, développement sur mesure, Android, iOS, logiciel"/>
             <title>GRANTSTECH</title>
             <link rel="icon" href='./../public/favicon.ico' />
             <link rel="apple-touch-icon" href='./../public/favicon.ico' />
             <link rel="manifest" href='./../public/manifest.json' />
         </Helmet>
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path={'/'} element={<MainComponent/>} />
                <Route path={'/Contacts'} element={<Contact/>} />
                <Route path={'/propos'} element={<AboutUs/>} />
                <Route path={'/career'} element={<Career/>} />
                <Route path={'/internship'} element={<Intern/>} />
                <Route path={'/jobs'} element={<Jobs/>} />
                <Route path={'/opportunity/:id/:slug'} element={<OffreDetails/>} />
                <Route path={'*'} element={<MainComponent/>}  />

            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
