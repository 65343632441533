import React from 'react';
import "./ItemContact.scss";
import {useNavigate} from "react-router-dom";



const ItemContact = (Props: any) => {
    const navigate=useNavigate()
    return (
        <div style={{cursor:'pointer'}} onClick={()=>{navigate(`${Props.to}`)}} className="item-contact center-horizontally position-center">
            <img src={Props.img} alt="img"/>
            <div className="content-item center-horizontally position-center">
                <h1>{Props.title}</h1>
            </div>
            <div className="mask center-horizontally position-center" />
        </div>
    );
};

export default ItemContact;
