import React from "react";
import './footer.scss'
import linked from '../../assets/img/linked.18c25df2749a4520245ef8c922dd9950.png'
import localisation from '../../assets/img/pin.png'
import logo from'../../assets/img/grants_logo.png'
import phone from'../../assets/img/phone-call.png'
import contact from'../../assets/img/mail (1).png'
import {useTranslation} from "react-i18next";


const Footer=()=>{
    const date = new Date().getFullYear();
    const {t}=useTranslation()

    return(
        <div className="copy-right">
            <div className="container center-horizontally space-between align-center">
                <div className={'left'}>
                    <p> © {date}  Grant's Tech </p>
                    <img src={logo} alt={'GRANTSTECH'}/>

                </div>


                <div className={'contact-container'}>
                    <div className={'localisation'}>
                        <img src={localisation}/>
                        <p className={'address'} >Lyon : </p>
                        <p className={'address'}>  143 Bis Rue Vendôme - 69006 Lyon
                        </p>
                    </div>

                    <div className={'localisation'}>
                        <img src={localisation}/>
                        <p className={'address'} >Tunis :</p>
                        <p className={'address'}>RDC, Lac Toba imp Amar
                            Les Beges du Lac 1 -1053 </p>
                    </div>
                </div>
                <div className="right">
                    <div className={'linked'}>
                        <a style={{textDecoration:'unset'}} href="mailto:contact@grantstech.io" target='_blank'> <p style={{top:'unset'}}>  contact@grantstech.io</p></a>
                        <div className="s-media center-horizontally">
                            <a href="mailto:contact@grantstech.io" target='_blank'><img src={contact} alt=""/></a>
                        </div>
                    </div>
                    <div className={'linked'}>
                        <p style={{top:'unset'}}> {t('contactSection.rejoindre')}</p>
                        <div className="s-media center-horizontally">
                            <a href='https://www.linkedin.com/company/grants-tech/posts/?feedView=all&viewAsMember=true' target='_blank'><img src={linked} alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer
