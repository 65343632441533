import React from "react";
import '../maincomponent/mainComponent.css'
import laptop from "../../assets/img/laptop (2).png";
import eye from "../../assets/img/view.png";
import pen from '../../assets/img/pen (1).png'
import verified from "../../assets/img/verified (1).png";
import TitleSection from "../titleSection/TitlteSection";
import '../../assets/i18n'
import {useTranslation} from "react-i18next";

const MissionSection = () => {
    const {t} = useTranslation()

    return (
        <section id={'mission'} className={'section'} style={{paddingTop: window.innerWidth > 768 ? '9vh' : '0vh'}}>
            <div id={'second'} style={{display: "unset"}} className="container reveal fade-bottom">
                <div className="title">
                    <div id="title-client">
                        <TitleSection section={'mission'} title={t('missionSection.title')} firstTitle={''}/>
                    </div>
                </div>
                <div className={'bloc-inspire'}>
                    <div className={'left-inspire'}>
                        <div className="blc-textt">
                            <p style={{
                                textAlign: 'justify',
                                margin: 'unset',
                                maxWidth: 'unset',
                                position: 'relative',
                                left: '16vw'
                                ,
                                width: window.innerWidth > 786 ? '70%' : ''
                            }}
                               id={'p'}>
                                {t('missionSection.description')}
                            </p>
                        </div>
                        <div className={'bloc-card-mission'}>
                            <div className={'card-inspire'}>
                                <div className={'headDivMission'}>
                                    <img alt={'logo'} src={laptop}/>
                                </div>
                                <div className={'botDivMission'}>
                                    <h2 className={'card-title'}> {t('missionSection.card1_title')}</h2>
                                    <p style={{textAlign: 'left'}} className={'detail-card-inspire'}>
                                        <ul>
                                            <p className={'detail-card-inspire'}>
                                                {t('missionSection.card1_desc')}
                                            </p>


                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card1_el1')}
                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card1_el2')}

                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card1_el3')}

                                                </p>

                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card1_el4')}


                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card1_el5')}

                                                </p>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className={'card-inspire'}>
                                <div className={'headDivMission'}>
                                    <img alt={'logo'} src={eye}/>

                                </div>
                                <div className={'botDivMission'}>
                                    <h2 className={'card-title'}> {t('missionSection.card2_title')}</h2>
                                    <p style={{textAlign: 'left'}} className={'detail-card-inspire'}>
                                        <ul>


                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card2_el1')}                                                </p>
                                            </li>
                                            <li>

                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card2_el2')}
                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card2_el3')}
                                                </p>
                                            </li>
                                        </ul>

                                    </p>
                                </div>


                            </div>
                            <div className={'card-inspire'}>
                                <div className={'headDivMission'}>
                                    <img alt={'logo'} src={pen}/>

                                </div>
                                <div className={'botDivMission'}>
                                    <h2 className={'card-title'}> {t('missionSection.card3_title')}</h2>
                                    <p style={{textAlign: 'left'}} className={'detail-card-inspire'}>
                                        <ul>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card3_el1')}
                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card3_el2')}
                                                </p>

                                            </li>


                                        </ul>


                                    </p>
                                </div>


                            </div>
                            <div className={'card-inspire'}>
                                <div className={'headDivMission'}>
                                    <img alt={'logo'} src={verified}/>
                                </div>
                                <div className={'botDivMission'}>
                                    <h2 className={'card-title'}> {t('missionSection.card4_title')}</h2>
                                    <p style={{textAlign: 'left'}} className={'detail-card-inspire'}>
                                        <ul>

                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card4_el1')}
                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card4_el2')}
                                                </p>
                                            </li>
                                            <li>
                                                <p className={'detail-card-inspire'}>
                                                    {t('missionSection.card4_el3')}

                                                </p>
                                            </li>

                                        </ul>

                                    </p>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default MissionSection
