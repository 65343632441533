import React, { useState } from "react";
import "./Contact.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import "../../assets/i18n";
import FormJob from "./FormJob";

const Contact = () => {

    return (
        <div className="contact" style={{paddingTop:window.innerWidth>768 ? '27vh':'20vh',height:'90.8vh'}} >
            <div style={{top:'unset'}} className="card-contact">
                    <FormJob isForJob={true}/>
            </div>
        </div>
    );
};

export default Contact;
