import React, {useEffect, useState} from "react";
import "./Career.scss";
import '../../components/maincomponent/mainComponent.css'
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import ContactSection from "../../components/contactSection/ContactSection";

const Career = () => {

    return (
        <div className="contact career" style={{paddingTop:window.innerWidth>768 ? '27vh':'17vh',height:'90.8vh'}} >
            <ContactSection/>
        </div>
    );
};

export default Career;
