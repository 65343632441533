import React, {useState} from "react";
import {Alert, Button, Form, Input, Modal, Space} from "antd";
import contact from "../../assets/img/mail (1).png";
import localisation from "../../assets/img/pin.png";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import jobContact from '../../assets/img/photo-1528747045269-390fe33c19f2.png'
import {useTranslation} from "react-i18next";

const FormJob = (props: any): JSX.Element => {
    const [form] = Form.useForm();
    const [successAlert, setSuccessAlert] = useState(false)
    const [errorAlert, setErrorAlert] = useState(false)
    const {t} = useTranslation()

    const onFinish = (values: any) => {
        emailjs.send("service_m458bcl", "template_j09pxrk", {
            from_name: values.nom,
            poste: values.Poste,
            objet: values.objet,
            email: values.Email,
            to_name: "bizdev@grantstech.io",
            message: values.Message,
            entreprise: values.Entreprise,
        }, 'RpNGhZI3_xLrE0dHL').then(() => {
            setTimeout(() => {
                setErrorAlert(true)
                Swal.fire(
                    'Success!',
                    'votre mail a été envoyé avec succès!',
                    'success'
                )
                form.resetFields();
            }, 100)


        }).catch(() => {
            setTimeout(() => {
                Swal.fire(
                    'Error!',
                    'votre mail n\'a pas pu être envoyé!',
                    'error'
                )
                form.resetFields();
            }, 500)

        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={'formContainer'}>
            {
                window.innerWidth > 768 && <div className={'contact_image'}>
                    <img
                        src={jobContact}/>
                </div>
            }
            <div className={'form'}>
                <Form
                    name="basic"
                    form={form}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 550}}
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="nom"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <input autoFocus={true} className="input__field" type="text" placeholder=" "/>
                            <span className="input__label">{t('contact.nom')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item
                        name="Entreprise"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <input className="input__field" type="text" placeholder=" "/>
                            <span className="input__label">{t('contact.entreprise')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item
                        name="Poste"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <input className="input__field" type="text" placeholder=" "/>
                            <span className="input__label">{t('contact.poste')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item
                        name="Email"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <input className="input__field" type="text" placeholder=" "/>
                            <span className="input__label">{t('contact.email')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item
                        name="objet"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <input className="input__field" type="text" placeholder=" "/>
                            <span className="input__label">{t('contact.objet')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item
                        name="Message"
                        rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                    >
                        <label className="input">
                            <textarea rows={6} style={{resize: 'none'}} maxLength={3000} className="input__field"
                                      placeholder=" "/>
                            <span className="input__label">{t('contact.message')}</span>
                        </label>
                    </Form.Item>
                    <Form.Item>
                        <Button style={{
                            backgroundColor: '#4981be',
                            position: 'relative',
                            left: window.innerWidth > 768 ? '8vw' : '25vw'
                        }} type="primary" htmlType="submit">
                            {t('contact.send')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={"information"}>
                <h2 style={{textTransform: 'uppercase'}}></h2>
                <p id={'p'}>
                    {t('contact.description1')}<br/>{t('contact.description2')}
                </p>
                <div className={'contact-list'}>
                    <img style={{width: '30px', height: '30px'}} src={contact}/>
                    <a style={{textDecoration: 'unset', color: 'black'}} href="mailto:contact@grantstech.io"
                       target='_blank'><p className={'detail-card-inspire'}> contact@grantstech.io</p></a>

                </div>
                <div className={'localisation'}>
                    <img style={{width: '30px', height: '30px'}} src={localisation}/>
                    <p className={'detail-card-inspire'}>Lyon : 143 Bis Rue Vendôme - 69006 Lyon</p>

                </div>
                <div className={'localisation'}>
                    <img style={{width: '30px', height: '30px'}} src={localisation}/>
                    <p className={'detail-card-inspire'}>
                        Tunis : RDC, Lac Toba imp Amar
                        Les Beges du Lac 1 -1053
                    </p>
                </div>

            </div>
            {
                window.innerWidth < 768 && <div className={'contact_image'}>
                    <img
                        src={jobContact}/>
                </div>
            }


        </div>
    )
}
export default FormJob
