import React, {useEffect, useRef, useState} from "react";
import './mainComponent.css'
import 'swiper/css';
import Secteur from "../secteur/Secteur";
import FirstSecteur from "../firstSecteur/FirstSecteur";
import AboutUsSection from "../aboutUsSection/AboutUsSection";
import ServiceSections from "../serviceSection/ServiceSections";
import ValeurSection from "../valeurSection/ValeurSection";
import MissionSection from "../missionSection/MissionSection";
import Review from "../review/Review";
import {CookieConsent, getCookieConsentValue, OPTIONS} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const MainComponent = () => {
    const {t, i18n} = useTranslation();

    // const [accepted, setAccepted] = useState<any>()
    // useEffect(() => {
    //     const x = getCookieConsentValue('Grant\'s Tech')
    //     if (!x) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "unset";
    //
    //     }
    //
    // }, [accepted])
    useEffect(() => {
        const reveals = document.querySelectorAll(".reveal");
        reveals[0].classList.add("active");
    }, [])


    function reveal() {
        const reveals = document.querySelectorAll(".reveal");

        for (let i = 0; i < reveals.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = reveals[i].getBoundingClientRect().top;
            const elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    function animation() {
        const card = document.querySelectorAll(".card");

        for (let i = 0; i < card.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = card[i].getBoundingClientRect().top;
            const elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                card[i].classList.add("active");
            } else {
                card[i].classList.remove("active");
            }
        }

    }

    function title() {
        const title = document.querySelectorAll(".title-section");

        for (let i = 0; i < title.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = title[i].getBoundingClientRect().top;
            const elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                title[i].classList.add("active");
            } else {
                title[i].classList.remove("active");
            }
        }

    }

    window.addEventListener('scroll', animation)
    window.addEventListener('scroll', title)
    window.addEventListener("scroll", reveal);
    const scrollRef = useRef(null)
   const scroll=()=>{

       if ( window.innerWidth < 760 ){
           window.scroll({
               top: - 80,
               behavior:'smooth'
           })
       }else{
           if (i18next.language==='fr'){
               window.scroll({
                   top: - 50,
                   behavior:'smooth'
               })
           }else{
               window.scroll({
                   top: 25,
                   behavior:'smooth'
               })
           }
       }
   }
    return (
        <div style={{overflow: 'hidden'}} className={'main_container'} ref={scrollRef}>
            <CookieConsent
                containerClasses={'cookies'}

                buttonText="Ok"
                cookieName="Grant's Tech"
                location={'bottom'}
                cookieValue={'true'}
                acceptOnScroll={false}
                acceptOnScrollPercentage={10}
                onAccept={scroll}
                style={{
                    backgroundColor: '#4981be',
                    height: '90px',
                    fontSize: '3vw'

                }}

                buttonStyle={{
                    color: "white",
                    fontWeight: "bolder",
                    backgroundColor: '#f07f18',
                    borderRadius: '10px'
                }}
            >
                <p className={'title-cookie'}>
                    {t('cookies.description')}
                </p>
            </CookieConsent>
            <FirstSecteur/>
            <AboutUsSection/>
            <ServiceSections/>
            <ValeurSection/>
            <MissionSection/>
            <Review/>
            <Secteur/>
            <div style={{height: '60px'}}/>


        </div>
    )
}
export default MainComponent
