import React from 'react';
import './titleSection.scss'

interface Props {
    firstTitle: string;
    title: string;

    section?:string
}

const TitleSection = (Props: Props) => {
    return (
        <div  className={`title-section ${Props?.section}`}>
            <h1>
                {Props.title}
            </h1>
        </div>
    );
};

export default TitleSection;
