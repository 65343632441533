import React from 'react';
import "./CardReview.scss";
import linked from "../../assets/img/linked.svg";

interface Props {
    img: string;
    name: string;
    job: string;
    comment: string;
    site?:string;
    subTitle?:string
}

const CardReview = (Props: Props) => {
    return (
        <div className="card-review">
            <div className="blc-top center-horizontally align-center">
                <div className={'div-img'}>
                    <img src={Props.img} alt="img"/>

                </div>
                <div className="txt-blc">
                    <h2>{Props.name}</h2>
                    <p>{Props.job}</p>
                    <a style={{textDecoration:'unset',color:'#4981be'}} href={`https://${Props?.site}`} target='_blank'> {Props.site}</a>
                    <p className={'subTitleReview'}>{Props.subTitle}</p>

                </div>
            </div>
            <div className="comment">
                <p className={'comment-detail'} >
                    {Props.comment}
                </p>
            </div>
        </div>
    );
};

export default CardReview;
