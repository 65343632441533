import React from 'react';
import './secteur.scss'
import TitleSection from "../titleSection/TitlteSection";
import '../../assets/i18n'
import edTech from './../../assets/img/Image2 (1).png'
import FinTech from './../../assets/img/FinTech.png'
import GreenTech from './../../assets/img/Image5.png'
import HealthTech from './../../assets/img/HealthTech.png'
import HrTech from './../../assets/img/Image9.png'
import InsurTech from './../../assets/img/InsureTech.png'
import PropTech from './../../assets/img/Image10 (2).png'
import SportTech from './../../assets/img/SportTech.png'
import {useTranslation} from "react-i18next";
import "swiper/css/pagination";
import "swiper/scss/navigation"
import '../review/review.scss'

const dataClients = [
    {
        class: "client1",
        src: edTech,
        title: 'EdTech'
    }, {
        class: "client1",
        src: GreenTech,
        title: 'GreenTech'
    }, {
        class: "client1",
        src: HealthTech,
        title: 'HealthTech'
    }, {
        class: "client1",
        src: SportTech,
        title: 'SportTech'
    },
    {
        class: "client1",
        src: FinTech,
        title: 'FinTech'
    }, {
        class: "client1",
        src: InsurTech,
        title: 'InsurTech'
    }, {
        class: "client1",
        src: PropTech,
        title: 'PropTech'
    }, {
        class: "client1",
        src: HrTech,
        title: 'HrTech'
    },

]
const Secteur = () => {
    const {t} = useTranslation()

    return (
        <section id={'last'} style={{paddingTop: window.innerWidth > 768 ? '7vw' : '0vh'}}>
            <div style={{display: "unset"}} className="container reveal fade-bottom">
                <div className="title">
                    <div id="title-client">
                        <TitleSection section={'secteur'} title={t('secteur.title')} firstTitle={''}/>
                    </div>
                </div>
                <div className={'bloc-inspire'}>
                    <div className={'left-inspire'}>
                        <div style={{padding: '20px'}} className="blc-textt">
                            {/*<h1 className={'blc-text'}>{'Notre Mission : '}</h1>*/}
                            <p style={{
                                textAlign: 'justify',
                                maxWidth: window.innerWidth > 768 ? '64.156vw' : '',
                            }} id={'p'}>
                                {
                                    t('secteur.description')
                                }
                            </p>
                        </div>
                        <div className={'secteur-bloc'}>
                            {
                                dataClients.map(obj => {
                                    return (
                                        <div className={'secteur-card'}>
                                            <img className={'clients-image'} style={{objectFit: 'contain'}}
                                                 src={obj.src}
                                                 alt="img"/>
                                            <p className={'detail-card-inspire'}
                                               style={{position: 'relative', top: '3px'}}> {obj.title}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}
export default Secteur
