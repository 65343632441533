import React from "react"
import {useLocation} from "react-router-dom";
import './offreDetails.scss'
import {Button, Form, Input, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import {useTranslation} from "react-i18next";

const OffreDetails = () => {
    const state = useLocation()
    const [form] = Form.useForm();
    const {t, i18n} = useTranslation();

    const onFinish = (values: any) => {
        console.log(values)

        emailjs.send("service_m458bcl", "template_j09pxrk", {
            email: values.Email,
            to_name: "bizdev@grantstech.io",
            objet:state.state.titre
        }, 'RpNGhZI3_xLrE0dHL').then(() => {
            setTimeout(() => {
                Swal.fire(
                    'Success!',
                    'votre mail a été envoyé avec succès!',
                    'success'
                )
                form.resetFields();
            }, 100)


        }).catch(() => {
            setTimeout(() => {
                Swal.fire(
                    'Error!',
                    'votre mail n\'a pas pu être envoyé!',
                    'error'
                )
                form.resetFields();
            }, 100)

        });
    };



    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const normFile = (e:any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <div className="mainContainer" style={{paddingTop:window.innerWidth>768 ? '10vh':'15vh'}} >
            <div className={'offre_details'}>
                <div className={'header'}>
                    <h2 className={'titre'}>{state.state.titre}</h2>
                </div>
                <div className={'body'}>
                    {
                        state?.state?.divBloc?.map((item: any) => {
                            return (
                                <>
                                    <h2>{item?.title}</h2>
                                    <p className={'information'}>
                                        {item?.text}
                                    </p>
                                    <ul>
                                        {
                                            item?.requiredWithoutTitle?.map((el: any) => (
                                                <li>
                                                    {el}
                                                </li>
                                            ))
                                        }
                                        {
                                            item?.requiredWithTitle?.map((el2: any) => (
                                                <>
                                                    <li style={{textDecoration:'underline 2px solid'}}>
                                                        {el2?.title}

                                                    </li>
                                                    <li style={{listStyleType: 'none'}}>
                                                        {
                                                            el2?.items?.map((el3: any) => (

                                                                <p>
                                                                    {el3}
                                                                </p>
                                                            ))
                                                        }
                                                    </li>
                                                </>

                                            ))
                                        }
                                    </ul>

                                </>
                            )
                        })
                    }

                </div>
                <div className={'footer'}>
                    <div className={'form'}>
                        <Form
                            name="basic"
                            form={form}
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            style={{maxWidth: 600}}
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >

                            <Form.Item
                                label={t('contact.email')}
                                name="Email"
                                rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="file"
                                getValueFromEvent={normFile}
                                       label="Upload" valuePropName="fileList">
                                <Upload  multiple={false} action="/upload.do" listType="picture-card">
                                    <div>
                                        <PlusOutlined/>
                                        <div style={{marginTop: 8}}>{t('contact.download')}</div>
                                    </div>
                                </Upload>
                            </Form.Item>
                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Button style={{backgroundColor:'#4981be'}} type="primary" htmlType="submit">
                                    {t('contact.submit')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default OffreDetails
