import React from "react";
import '../maincomponent/mainComponent.css'
import ajoutValeur from "../../assets/img/ajoutValeur.jpg";
import respect from "../../assets/img/respect.jpg";
import talent from "../../assets/img/talent.jpg";
import engagement from "../../assets/img/engagement.jpg";
import TitleSection from "../titleSection/TitlteSection";
import '../../assets/i18n'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
const ValeurSection=()=>{
    const {t}=useTranslation()
    return(
        <section id={'valeur'} className={'section'} style={{alignSelf: 'center',paddingTop: window.innerWidth > 768 ? '9vh' : '0vh',height:window.innerWidth >768 ? '80vh' :''}}>
            <div id={'first'} style={{display: "unset"}} className="container reveal fade-bottom">
                <div className="title">
                    <div id="title-client">
                        <TitleSection title={t('valeurSection.title')} firstTitle={''}/>
                    </div>
                </div>
                <div className={'bloc-inspire'}>
                    <div className={'left-inspire'}>

                        <div className={'bloc-card'}>
                            <div className={'card-inspire'}>
                                <div className={'headDiv'}>
                                    <img alt={'logo'} src={talent}/>
                                </div>
                                <div className={'botDiv'}>
                                    <div style={{display:'flex',gap:'5px'}} className={'title'}>
                                        <h2 style={{top:i18n.language==='en'?'-0.1vh':'-8px',position:'relative',left:i18n.language==='en'?'5px':'0'}} className={i18n.language==='fr'? 'card-title g':'card-title first'}> {t('valeurSection.card1_title1')}</h2>
                                        <h2 style={{left:i18n.language==='en'?'6px':'0'}} className={'card-title '}> {t('valeurSection.card1_title2')}</h2>
                                        <h2 style={{top:i18n.language==='en'?'-8px':'-0.1vh'}} className={i18n.language==='fr'? 'card-title first':'card-title g'}> {t('valeurSection.card1_title3')}</h2>
                                    </div>


                                    {/*<h2 className={'card-title g'}> {t('valeurSection.card1_title')}</h2>*/}


                                    <p className={'detail-card-inspire'}>{t('valeurSection.card1_desc')}</p>
                                </div>


                            </div>
                            <div className={'card-inspire'}>
                                <div className={'headDiv'}>
                                    <img alt={'logo'} src={respect}/>
                                </div>
                                <div className={'botDiv'}>
                                    <h2 className={'card-title r'}> {t('valeurSection.card2_title')}</h2>
                                    <p className={'detail-card-inspire'}>
                                        {t('valeurSection.card2_desc')}
                                    </p>
                                </div>

                            </div>
                            <div className={'card-inspire'}>
                                <div className={'headDiv'}>
                                    <img alt={'logo'} src={ajoutValeur}/>
                                </div>
                                <div className={'botDiv'}>
                                    <div style={{display:'flex',gap:'2px'}} className={'title'}>
                                        <h2 style={{top:i18n.language==='en'?'-0.1vh':'-8px'}} className={i18n.language==='fr'? 'card-title a':'card-title first'}> {t('valeurSection.card3_title1')}</h2>
                                        <h2 style={{left:i18n.language==='en'?'6px':'0'}} className={'card-title '}> {t('valeurSection.card3_title2')}</h2>
                                        <h2 style={{top:i18n.language==='en'?'-8px':'0'}} className={i18n.language==='fr'? 'card-title first':'card-title a'}> {t('valeurSection.card3_title3')}</h2>
                                    </div>

                                    <p className={'detail-card-inspire'}>
                                        {t('valeurSection.card3_desc')}
                                    </p>
                                </div>

                            </div>
                            <div className={'card-inspire'}>

                                <div className={'headDiv'}>
                                    <img alt={'logo'} src={engagement}/>
                                </div>
                                <div className={'botDiv'}>
                                <div className={'title'}>
                                    <h2 className={'card-title first'}>
                                        {t('valeurSection.card4_title_first')}
                                    </h2>
                                    <h2 className={'card-title second'}>
                                        {t('valeurSection.card4_title_second')}
                                    </h2>
                                    <h2 className={'card-title n'}>
                                        {t('valeurSection.card4_title_third')}
                                    </h2>
                                    <h2 className={'card-title last_char'}>
                                        {t('valeurSection.card4_title_four')}
                                    </h2>
                                </div>
                                    <p className={'detail-card-inspire'}>
                                        {t('valeurSection.card4_desc')}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default ValeurSection
