import React from "react";
import './Card.scss'
import {Card} from "antd";
import {useNavigate} from "react-router-dom";
const CardItem = (props: any): JSX.Element =>{
    const navigate=useNavigate()
    return(
        <div className={'card-container1'}>

            <Card
                onClick={()=>{navigate(`/opportunity/${props?.props.id}/${props?.props?.titre.toString().replace(/\s/g, '')}`,
                    {state:props?.props})}}
                  bordered={false}   title={props?.props?.titre}  >
                <p className={'detail-card-inspire'}>{props?.props?.type}</p>
                {/*<p className={'detail-card-inspire'}>{props?.props?.level}</p>*/}
                {/*<p className={'detail-card-inspire'}>{props?.props?.date}</p>*/}
            </Card>
        </div>
    )
}
export default CardItem
