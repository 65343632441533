import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    fr: {
        translation: {
            home: {
                review: {
                    review_1: ' Nous avons pris la décision de solliciter l\'aide de Grant\'s Tech pour tester nos nouveaux développements et offrir un meilleur service à nos clients. Leur expérience et leur travail minutieux nous permettent d\'identifier et de résoudre les problèmes, garantissant une amélioration continue de notre application. Une collaboration efficace pour une expérience de qualité.',
                    review_2: 'En travaillant main dans la main avec Grant\'sTech, nous avons constaté à quel point nos compétences se complètent harmonieusement. Leur savoir-faire dans le domaine du développement mobile/web s\'est parfaitement accordé avec notre expertise graphique, ainsi qu\'avec nos compétences en matière d\'UX/UI et Communication. Cette collaboration a permis de créer des projets d\'une qualité exceptionnelle, répondant aux attentes et aux exigences les plus élevées de nos clients communs.\n',
                    review_3: ' L\'équipe Grant\'s Tech nous accompagne depuis plusieurs années dans nos projets, et nous aide à nous développer et à proposer de nouvelles idées, toujours dans le but d\'améliorer l\'expérience de l\'utilisateur.\n' +
                        '\n' +
                        'Tous font preuve d\'un professionnalisme, d\'une patience et d\'une réactivité incroyables.',
                    review_4: 'En tant que membre d’un groupe de médecins, je souhaite féliciter vivement l’équipe Grant’s Tech pour leur engagement exceptionnel dans le développement de l\'application basée sur l\'idée collective de notre groupe. Leur travail acharné, leur passion et leur expertise ont transformé cette idée en une réalité concrète, offrant ainsi des perspectives prometteuses pour la communication dans le domaine médical. Ils ont su comprendre les enjeux médicaux et les besoins spécifiques de notre profession, et ont travaillé avec ardeur pour développer une application qui répond à ces besoins de manière efficace et pratique.',
                    review_5: 'I am pleased to share my experience of working with Grant\'s Tech, who have been instrumental in the development of our new platform at Sustainable Square. Throughout our collaboration, the team at Grant\'s Tech demonstrated an exceptional level of professionalism, flexibly adapting their approach to align with our requirements while consistently adhering to set timelines and milestones. Their unwavering reliability under tight schedules, coupled with a demonstrable commitment to providing high-quality service, instilled a sense of confidence in our team. The end-product, a cutting-edge platform that seamlessly integrates our unique processes, stands as a testament to their ingenuity and technical expertise. Therefore, I wholeheartedly recommend Grant\'s Tech to any organization in need of a dependable tech partner who can deliver beyond expectations.'
                }
            },
            header: {
                home: "Accueil",
                aboutUs: "Qui sommes nous?",
                expertise: "Expertise",
                notreMission: "Notre Mission",
                nosValeur: "Nos Valeurs",
                recruitment: "Recrutement",
                environment: "Environnement",
                Portfolio: "Portfolio",
                Contact: "Contact",
                Carierre: 'Nous rejoindre',
                english: "Anglais",
                french: "Français",
            },
            cookies:{
                description:'  Ce site n\'utilise pas des cookies. Les données traitées dans les formulaires "Contact" et "Nous\n' +
                    '                    rejoindre" seront sauvegardées\n' +
                    '                    dans notre CRM et seront utilisées uniquement pour répondre à vos messages que nous recevrons par\n' +
                    '                    E-mail.',
                btn:'ok'
            },
            contact:{
              title:'CONTACTEZ-NOUS\n',
              description1:'Vous avez une idée d\'un projet informatique innovant et plein de challenges ? Nous serons votre partenaire .',
              description2:'Contactez-nous pour plus d\'informations sur nos services.',
                nom :'Nom',
                entreprise:'Entreprise',
                poste:'Poste',
                email:'Email',
                objet:'Objet',
                message:'Message',
                send:'Envoyer',
                download:'Télécharger',
                candidature:'Candidature spontanée',
                erreur:'Ce champ est obligatoire !',
                submit:'Submit',




            },
            secteur:{
                title:'NOS SECTEURS',
                description:'Grant\'s Tech opère dans de multiples secteurs d\'activités grace à la polyvalence de nos équipes. Nous nous adaptons à votre besoin car à chaque métier, sa spécificité. Grant\'s Tech développe une approche par secteur d\'activité pour vous apporter la solution la plus adaptée.\n' +
                    '\n',
            },
            review: {
              title:'ILS NOUS FONT CONFIANCE\n',
                description:'Quoi de mieux que de laisser nos clients parler de nous. Ci-dessous quelques exemples de témoignages clients qui vous expliquent pourquoi ils ont choisi Grant\'s tech pour les accompagner dans leurs projets.\n' +
                    '\n',
                description2:'* D\'autres témoignages sont disponibles sur demande.\n'

            },
            firstSecteur: {
                description: 'En unissant nos forces, nous allons construire ensemble votre avenir technologique et ' +
                    'révolutionner votre monde numérique. L\'innovation est notre moteur, votre satisfaction notre but.',
                title1: 'Votre PARTENAIRE dans',
                title2: 'la TRANSFORMATION digitale',
                paragraphe: 'Quel que soit votre secteur d\'activité, votre besoin ou votre objectif, nos équipes vous proposeront des solutions innovantes et 100% sur-mesure en adéquation avec votre métier. Nous vous accompagnerons dans la digitalisation et l\'automatisation de vos processus en traduisant vos besoins en solutions web et mobiles innovantes.\n' +
                    '\n' +
                    'De nos jours, nombreuses sont les agences digitales qui proposent des solutions sur-mesure et à des prix attractifs. En réalité, ce qu’elles proposent c’est de « la poudre de perlimpinpin ».' +
                    ' Il s’agit d’une couche de configuration appliquée sur des outils CMS / low-code / no-code. ' +
                    'Ces solutions sont certes rapides à mettre en place mais dans la plupart des cas ne permettent pas de répondre à la totalité de vos besoins et créent des dépendances avec l’outil utilisé. Vous risquez de devoir adapter vos processus au mode de fonctionnement de l’applicatif.\n' +
                    '\n' +
                    'Chez Grant’s Tech, nous vous proposons du vrai « sur-mesure » et nous mettons la puissance de la technologie au service de votre succès.',
                nvProjet: 'Obtenir un devis gratuit',
                experience: 'Expérience',
                projet: 'Projets',
                employe: 'Employées',
                client: 'Clients',
                expertise: 'Nos Compétence',


            },
            intern:{
                typeTravail: 'Télétravail',
                title:'NOUS REJOINDRE\n',
              description:' Venez vite nous rejoindre!\n' +
                  '                    Votre carrière est le reflet de vos ambitions. Apportez vos talents et votre curiosité d’esprit avec\n' +
                  '                    vous.\n' +
                  '                    Ici, vous pourrez allier votre ingéniosité aux dernières technologies pour relever des défis hors du\n' +
                  '                    commun.',
            },
            aboutSection: {
                propos: 'QUI SOMMES NOUS ?',
                description: ' Fondée par deux anciens dirigeants du monde de l’édition et des ESN, Grant’s Tech est\n' +
                    '                        spécialisée dans le domaine des nouvelles technologies et nous proposons de vous accompagner\n' +
                    '                        sur tous vos projets d’ingénierie informatique. Positionnée comme une entreprise innovante\n' +
                    '                        dans\n' +
                    '                        le secteur de l’informatique,\n' +
                    '                        cette innovation réside dans la qualité et l’approche unique que nous proposons à nos\n' +
                    '                        clients.\n' +
                    'Notre équipe pluridisciplinaire assurera une gestion de projet fluide et couvrira tous vos' +
                    ' besoins : Architecte solution, Consultant UX/UI, Designer, Chef de projet, Consultant fonctionnel, ' +
                    'Consultant technique Front-end et Back-end, Intégrateur technique, etc.\n ' +
                    'En unissant nos forces, nous allons construire ensemble votre avenir technologique et révolutionner votre monde numérique. L\'innovation est notre moteur, votre satisfaction notre but.'


            },
            serviceSection: {
                title: 'Notre expertise',
                hover1: 'Documentation du projet',
                hover2: 'Pilotage des tests',
                hover3: 'Pilotage du projet',
                title2: 'POURQUOI NOUS CHOISIR',
                title3: 'Notre approche consiste à créer des applications métier web et mobiles qui répondent à 100% aux besoins spécifiques de nos clients. Ceci implique un travail en étroite collaboration avec le client pour comprendre ses besoins, ses objectifs ainsi que sa vision.\n' +
                    '\n' +
                    'Un vrai développement sur-mesure permet aux entreprises d\'avoir un avantage concurrentiel en offrant une expérience utilisateur unique et en proposant des fonctionnalités adaptées à leurs besoins. Ce type d’applicatif est conçu pour être évolutif et adaptatif. Ceci lui confère une facilité d’être mis à jour et amélioré au fil du temps pour mieux répondre aux changements des besoins de nos clients et des utilisateurs finaux du système.\n' +
                    '\n' +
                    'Nous vous encourageons vivement d’opter pour un développement web ou mobile sur mesure et nos équipes vous accompagneront dans les choix technologiques ainsi que les outils qui conviennent le mieux à votre projet.',
                card1_title: 'DÉVELOPPEMENT MOBILE',
                title4: 'De nos jours, les entreprises évoluent dans un contexte technologique en éternelle évolution et plein de nouveaux défis où l’équilibre entre le coût et les risques informatiques est fragile.\n' +
                    'Bien plus qu’un simple prestataire, Grant’s Tech se positionne comme votre partenaire de réussite alliant excellence, innovation et application d’une méthodologie éprouvée. Nos équipes vous accompagnent dans la gestion de vos projets sur les volets suivants',
                card2_title: 'DÉVELOPPEMENT WEB',
                card3_title: 'CONSULTING',
                card1_desc: ' Nous proposons des services de développement mobile de haute qualité, adaptés à vos besoins spécifiques. Grace à notre équipe de développeurs expérimentés, nous créons des applications mobiles innovantes et conviviales sur différentes plateformes. Que vous souhaitiez développer une application native iOS ou Android, une solution multiplateforme ou même améliorer votre application mobile existante, nous avons la solution.',
                card2_desc: 'Nous offrons des services de développement web sur mesure pour répondre à vos besoins spécifiques. Nous sommes une équipe de développeurs expérimentés et passionnés qui utilisent les dernières technologies pour créer des sites web performants, conviviaux et adaptatifs sur différentes plateformes.' +
                    '\n',
                card3_desc: ' Nous sommes fiers d\'offrir des services de conseil en technologies de l\'information pour aider nos clients à digitaliser et automatiser leurs processus et leur stratégie technologique. Avec notre expertise approfondie et notre maitrise des meilleures pratiques du secteur, nous aidons nos clients à prendre les meilleurs décisions et à atteindre leurs objectifs.' +
                    '\n',
            },
            valeurSection: {
                title0: 'GRANTS VALEUR',
                title: 'NOS VALEURS',
                title2: 'Notre essence tire son inspiration de:',
                title3: 'Notre entreprise est solidement ancrée sur un socle de valeurs qui orientent toujours nos décisions, ainsi que sur une équipe de collaborateurs compétents et engagés.',
                card1_title1: 'Gestion ',
                card1_title2: ' des ',
                card1_title3: ' talents',
                card2_title: 'Respect des individus ',
                card3_title1: 'Ajout',
                card3_title2: 'de',
                card3_title3: 'valeur ',
                card4_title_first: 'E ',
                card4_title_second: 'N ',
                card4_title_third: 'gagemen ',
                card4_title_four: 'T',
                card4_t: 'T ',

                card1_desc: 'Chaque collaborateur est unique dans notre entreprise. Attirer, développer et retenir les meilleurs talents sont nos atouts.\n' +
                    '\n' +
                    'Faire preuve d’une attitude positive et' +
                    ' favoriser ' + '\n' +

                    'un environnement collaboratif et solidaire sont nos priorités.',
                card2_desc: 'La diversité et les contributions originales sont au centre de notre stratégie.\n' +
                    '\n' +
                    'Favoriser un environnement de confiance, respectueux de chacun et inclusif représente notre identité.',
                card3_desc: 'Nos clients sont notre raison d’être. Nous nous engageons à leur générer de la valeur. Il ne s’agit pas simplement de donner des idées ou d’écrire du code : nous devons contribuer véritablement à instaurer des changements tangibles et positifs au sein de la cartographie IT de nos clients.  \n' +
                    '\n',
                card4_desc: 'Notre passion consiste à aligner nos résultats avec le succès de nos clients.\n' +
                    '\n' +
                    'Pour cette raison, nous devons leur fournir le meilleur des services et un engagement fort et éthique dans tout ce que nous entreprenons.'

            },
            missionSection: {
                title: 'Notre Mission',
                description: 'De nos jours, les entreprises évoluent dans un contexte technologique en éternelle évolution et plein de nouveaux défis où l’équilibre entre le coût et les risques informatiques est fragile. Bien plus qu’un simple prestataire, Grant’s Tech se positionne comme votre partenaire de réussite alliant excellence, innovation et application d’une méthodologie éprouvée. Nos équipes vous accompagnent dans la gestion de vos projets sur les volets suivants',
                card1_title: 'Digitalisation des processus',
                card2_title: 'DOCUMENTATION DU PROJET\n',
                card3_title: 'PILOTAGE DU PROJET\n',
                card4_title:'PILOTAGE DES TESTS\n',
                card1_desc:'Nos équipes peuvent vous accompagner dans votre transformation digitale et l\'automatisation de vos processus à travers l\'utilisation de technologies et plateformes de pointe',
                card1_el1:'Developpement Front to Back\n' +
                    '\n',
                card1_el2:'Déploiement cloud\n' +
                    '\n',
                card1_el3:'Machine Learning\n' +
                    '\n',
                card1_el4:'Devops\n' +
                    '\n',
                card1_el5:'UI/UX',
                card2_el1:'Rédaction de l’expression de Besoin (EdBE)\n' +
                    '\n',
                card2_el2:'Rédaction des spécifications fonctionnelles et techniques (SFG, STG, SFD, STD, Etc.)\n' +
                    '\n',
                card2_el3:'Rédactions des cahiers de test\n' +
                    '\n' +
                    '\n',
                card3_el1:'Avec un retour sur expérience de plus de 15 années dans la conduite des projets IT, nous sommes capables d’identifier les risques associés au déroulement du projet et mettre en place une gestion personnalisée qui permet à nos clients d’avoir une implémentation réussie en minimisant les coûts et les délais\n' +
                    '\n',
                card3_el2:'Encadrement et organisation des différentes instances : comité de projet, comité de pilotage, etc.',
                card4_el1:'Elaboration de la stratégie de test\n' +
                    '\n',
                card4_el2:'Préparation des scénarios de test\n' +
                    '\n',
                card4_el3:'Reporting',

            },
            secteurSection: {
                title: 'GRANTS SECTEUR',
                title1: 'NOS SECTEURS',
            },
            contactSection: {
                title: 'voulez vous nous rejoindre?',
                card1_title: 'Stage',
                card2_title: 'Emploi',
                rejoindre: 'Rejoignez-nous'
            },
        },
    },
    en: {
        translation: {
            header: {
                home: "Home",
                aboutUs: "About Us",
                recruitment: "Recruitment",
                environment: "Environment",
                Portfolio: "Portfolio",
                Contact: "Contact",
                Carierre: 'Join Us',
                english: "English",
                french: "French",
                expertise: "Expertise",
                notreMission: "Our Mission",
                nosValeur: "Our Values",

            },
            home: {
                review: {
                    review_1: ' Nous avons pris la décision de solliciter l\'aide de Grant\'s Tech pour tester nos nouveaux développements et offrir un meilleur service à nos clients. Leur expérience et leur travail minutieux nous permettent d\'identifier et de résoudre les problèmes, garantissant une amélioration continue de notre application. Une collaboration efficace pour une expérience de qualité.',
                    review_2: 'En travaillant main dans la main avec Grant\'sTech, nous avons constaté à quel point nos compétences se complètent harmonieusement. Leur savoir-faire dans le domaine du développement mobile/web s\'est parfaitement accordé avec notre expertise graphique, ainsi qu\'avec nos compétences en matière d\'UX/UI et Communication. Cette collaboration a permis de créer des projets d\'une qualité exceptionnelle, répondant aux attentes et aux exigences les plus élevées de nos clients communs.\n',
                    review_3: ' L\'équipe Grant\'s Tech nous accompagne depuis plusieurs années dans nos projets, et nous aide à nous développer et à proposer de nouvelles idées, toujours dans le but d\'améliorer l\'expérience de l\'utilisateur.\n' +
                        '\n' +
                        'Tous font preuve d\'un professionnalisme, d\'une patience et d\'une réactivité incroyables.',
                    review_4: 'En tant que membre d’un groupe de médecins, je souhaite féliciter vivement l’équipe Grant’s Tech pour leur engagement exceptionnel dans le développement de l\'application basée sur l\'idée collective de notre groupe. Leur travail acharné, leur passion et leur expertise ont transformé cette idée en une réalité concrète, offrant ainsi des perspectives prometteuses pour la communication dans le domaine médical. Ils ont su comprendre les enjeux médicaux et les besoins spécifiques de notre profession, et ont travaillé avec ardeur pour développer une application qui répond à ces besoins de manière efficace et pratique.',
                    review_5: 'I am pleased to share my experience of working with Grant\'s Tech, who have been instrumental in the development of our new platform at Sustainable Square. Throughout our collaboration, the team at Grant\'s Tech demonstrated an exceptional level of professionalism, flexibly adapting their approach to align with our requirements while consistently adhering to set timelines and milestones. Their unwavering reliability under tight schedules, coupled with a demonstrable commitment to providing high-quality service, instilled a sense of confidence in our team. The end-product, a cutting-edge platform that seamlessly integrates our unique processes, stands as a testament to their ingenuity and technical expertise. Therefore, I wholeheartedly recommend Grant\'s Tech to any organization in need of a dependable tech partner who can deliver beyond expectations.'
                }
            },
            secteur:{
                title:'OUR INDUSTRIES',
                description:'Grant\'s Tech operates in multiple industries thanks to the versatility of our teams. We adapt to your business requirements because every industry has its own specificities. Grant\'s Tech develops an industry-specific approach to provide you with the most suitable solution.',


            },
            missionSection: {
                title: 'OUR MISSION',
                description: 'In today\'s ever-evolving technological landscape, businesses face numerous challenges, particularly in balancing the cost and risks associated with IT. Grant\'s Tech goes beyond being an ordinary service provider and positions itself as your partner for success, combining excellence, innovation, and the application of a proven methodology. Our teams accompany you in managing your projects in the following areas:',
                card1_title: 'DIGITALIZATION OF PROCESSES',
                card3_title: 'PROJECT MANAGEMENT',
                card4_title:'TEST MANAGEMENT',
                card1_desc:'Our teams can support you in your digital transformation and the automation of your processes through the use of cutting-edge technologies and platforms',
                card1_el1:'Front to Back Development' +
                    '\n',
                card1_el2:'Cloud Deployment' +
                    '\n',
                card1_el3:'Machine Learning\n' +
                    '\n',
                card1_el4:'Devops\n' +
                    '\n',
                card1_el5:'UI/UX',
                card2_title: 'PROJECT DOCUMENTATION',

                card2_el1:'Writing the Statement of Work (SOW)' +
                    '\n',
                card2_el2:'Writing business and technical specifications' +
                    '\n',
                card2_el3:'Test cases documentation' +
                    '\n' +
                    '\n',
                card3_el1:'With over 15 years of experience in IT project management, we have the expertise to identify risks associated with project execution and implement customized management approaches that enable our clients to achieve successful implementations while minimizing costs and timelines.' +
                    '\n',
                card3_el2:'Supervision and organization of various project instances: project committee, steering committee, etc.',
                card4_el1:'Test strategy' +
                    '\n',
                card4_el2:'Management of test scenarios' +
                    '\n',
                card4_el3:'Reporting',

            },
            cookies:{
                description:'This site does not use cookies. The data processed in the "Contact" and "Join us" forms will be saved in our CRM and will be used only to respond to your messages that we receive by e-mail.',
                btn:'ok'
            },
            review: {
                title:'THEY TRUST US',
                description:'What better way to showcase our capabilities than letting our clients speak for us? Below are a few examples of client/partners testimonials explaining why they chose Grant\'s Tech to support them in their projects',
                description2: '*Additional testimonials are available upon request.',
            },
            contact:{
                title:'CONTACT US',
                description1:'Do you have an innovative and challenging IT project idea? We will be your partner.',
                description2:'Fill out the form and our experts will reach out to you.',
                nom :'Full Name',
                entreprise:'Company',
                poste:'Position',
                email:'Email',
                objet:'Object',
                message:'Message',
                send:'Send',
                download:'Download',
                candidature:'Spontaneous application\n',
                erreur:'This filed is required !',
                submit:'Submit',



            },
            firstSecteur: {
                description: 'By joining forces, we can imagine and develop innovations for your business',
                title1: 'Your PARTNER in',
                title2: 'Digital TRANSFORMATION',
                paragraphe: 'Regardless of your industry, requirements or goals,' +
                    ' our teams will provide you with innovative and 100% tailor-made' +
                    ' solutions that align with your business. We will support you in the' +
                    ' digitalization and automation of your processes by translating your needs' +
                    ' into innovative web and mobile solutions. Nowadays, there are numerous IT ' +
                    'services companies that offer tailor-made solutions at attractive prices. However,' +
                    ' what they offer is often just "smoke and mirrors." It involves a layer of ' +
                    'configuration applied through CMS / low-code / no-code. While these solutions' +
                    ' may be quick to implement, they often fail to meet all of your needs and create ' +
                    'dependencies on the tool used. You may end up having to adapt your processes to ' +
                    'fit the application\'s features.' +
                    ' At Grant\'s Tech, we offer true "tailor-made" solutions and harness the power ' +
                    'of technology to support your success.',
                nvProjet: 'Get your free quotation',
                experience: 'Experience',
                projet: 'Projects',
                employe: 'Employees',
                client: 'Clients',
                expertise: 'Our Expertise'

            },
            intern:{
                typeTravail: 'Remote',
                title:'JOIN US',
                description:'Come join us quickly! Your career reflects your ambitions. Bring your talents and curiosity with you. Here, you can combine your ingenuity with the latest technologies to take on extraordinary challenges.'

            },
            aboutSection: {
                propos: 'ABOUT US',
                description: 'Founded by two former executives from the ISV (Independent Software Vendor) and IT ' +
                    'consulting industry, Grant\'s Tech specialized in digital innovation and' +
                    ' new technologies, we provide you with support on all your IT projects. Positioned as' +
                    ' an innovative company in the IT sector, our innovation lies in the quality and the unique' +
                    ' approach we offer to our clients. Our multidisciplinary team ensures smooth project management' +
                    ' and covers all your needs: Solution Architect, UX/UI Consultant, Designer, Project Manager, Business' +
                    ' Consultant, Front-end and Back-end Technical Consultant, Technical Integrator, and more. By joining ' +
                    'forces, we will build your technological future together and transform ' +
                    'your digital world. Innovation is our driving force, and your satisfaction is our goal.'

            },
            serviceSection: {
                title0: 'GRANSTS VALEUR',
                title: 'OUR EXPERTISE',
                title2: 'WHY CHOOSE US',
                hover1: 'Project documentation',
                hover2: 'Test strategy',
                hover3: 'Project management',
                title3: '' +
                    'Our approach is to create web and mobile applications that fully meet the specific needs of our clients. This involves working closely with the client to understand their requirements, goals, and vision. Rightful custom development enables companies to gain a competitive advantage by providing a unique user-experience and offering features tailored to their needs. This type of solution is designed to be scalable and adaptable, allowing for easy growth and improvements over time to better meet the changing needs of our clients and end-users. We strongly encourage you to consider custom web or mobile development, and our teams will guide you in making the best choices and deciding on the tools that best suit your project.',
                card1_title: 'MOBILE DEVELOPMENT',
                card2_title: 'WEB DEVELOPMENT',
                card3_title: 'CONSULTING',
                card1_desc: 'We offer high-quality mobile development services tailored to your specific needs. With our team of experienced developers, we create innovative and user-friendly mobile applications on various platforms. Whether you want to develop a native iOS or Android app, a cross-platform solution, or even add to your existing mobile application, we have the solution for you.',
                card2_desc: 'We provide custom web development services to meet your specific requirements. We are a team of experienced and passionate developers who leverage the latest technologies to create high-performing, user-friendly, and responsive websites across different platforms.',
                card3_desc: 'We take pride in offering information technology consulting services to assist our clients in digitalizing and automating their processes and technology strategy. With our in-depth expertise and mastery of industry best practices, we help our clients make informed decisions and achieve their goals.',
            },
            valeurSection: {
                title: 'OUR VALUES',
                title0: 'GRANSTS VALUE',
                title2: 'Our essence draws its inspiration from:',
                title3: 'Our company is firmly anchored on a foundation of values that always guide our decisions, as well as on a team of competent and committed employees.',
                card1_title1: 'TALENT ',
                card1_title2: 'MANA',
                card1_title3: 'GEMENT',
                card2_title: 'RESPECT FOR INDIVIDUALS',
                card3_title1: 'VALUE',
                card3_title2: '',
                card3_title3: ' ADDITION ',
                card1_desc: 'Every employee is unique in our company. Attracting, developing, and retaining the best talents are our strengths. Demonstrating a positive attitude and fostering a collaborative and supportive environment are our priorities.',
                card2_desc: 'Diversity and unique contributions are at the core of our strategy. Fostering a trusting, respectful, and inclusive environment represents our identity.',
                card3_desc: 'Our clients are our reason for being. We are committed to generating value for them. It\'s not just about giving ideas or writing code: we must genuinely contribute to bringing about tangible and positive changes within our clients\' IT landscape.',
                card4_desc: 'Our passion lies in aligning our outcomes with the success of our clients. For this reason, we must provide them with the best services and a strong and ethical commitment in everything we undertake.',
                card4_title_first: 'E ',
                card4_title_second: 'N ',
                card4_title_third: 'gagemen ',
                card4_title_four: 'T',
                card4_t: 'T ',
            },
            secteurSection: {
                title: 'GRANTS SECTEUR\n' +
                    '\n',
                title1: 'OUR SECTORS',
            },
            contactSection: {
                title: 'do you want to join us?',
                card1_title: 'Internship',
                card2_title: 'Job',
                rejoindre:'Join us',

            }

        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "fr",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
