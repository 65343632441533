import React, {useState} from "react";
import "./Career.scss";
import '../../components/maincomponent/mainComponent.css'

import "swiper/css";
import "swiper/css/navigation";
import {useTranslation} from "react-i18next";
import "../../assets/i18n";
import CardItem from "./Card";
import {Button, Form, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import stage from '../../assets/img/Stage.png'
import TitleSection from "../../components/titleSection/TitlteSection";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

const Intern = () => {

    const {t, i18n} = useTranslation();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log(values)

        emailjs.send("service_m458bcl", "template_j09pxrk", {
            from_name: values.nom,
            email: values.E_mail,
            to_name: "bizdev@grantstech.io",
            message: values.Message,
        }, 'RpNGhZI3_xLrE0dHL').then(() => {
            setTimeout(() => {
                Swal.fire(
                    'Success!',
                    'votre mail a été envoyé avec succès!',
                    'success'
                )
                form.resetFields();
            }, 100)


        }).catch(() => {
            setTimeout(() => {
                Swal.fire(
                    'Error!',
                    'votre mail n\'a pas pu être envoyé!',
                    'error'
                )
                form.resetFields();
            }, 100)

        });
    };



    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const dataContact = [
        {
            id: 1,
            img: 'https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&w=1600',
            title: 'Welcome with us',
            text: '',
        },

    ];
    const Job = [
        {

            id: 2,
            type: t('intern.typeTravail'),
            titre: 'PFE .Net core microservices\n',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 01/02/2023',
            divBloc: [
                {
                    title: '',
                    text: 'Nous recherchons deux projets de fin d\'étude Fukk stack pour travailler sur des projets dans les domaines de la technologie du sport et de la technologie de la santé. Les deux projets sont orientés services et basés sur des fonctions lambda et un déploiement sur AWS ou Microsoft Azure.\n' +
                        '\n'

                },
                {
                    requiredWithTitle: [
                        {
                            id: 2,
                            title: 'Description',
                            items: ['Au sein de notre département d’édition de logiciels, vous allez participer à la création de modèle de computer vision pour la séparation et l’identification de données mutlilangues sur des supports et formats différents\n']
                        },
                        {
                            id: 3,
                            title: 'Environnement technique :\n',
                            items: ['Machine Learning\n', 'Computer Vision', 'Microsoft Azure Ml', 'Google AI', 'Python']
                        }
                    ]
                }
            ],
        },
        {
            id: 3,
            type: t('intern.typeTravail'),
            titre: 'PFE Computer vision model\n',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 01/02/2023',
            divBloc: [
                {
                    title: '',
                    text: 'Nous recherchons deux projets de fin d\'étude Fukk stack pour travailler sur des projets dans les domaines de la technologie du sport et de la technologie de la santé. Les deux projets sont orientés services et basés sur des fonctions lambda et un déploiement sur AWS ou Microsoft Azure.\n' +
                        '\n'

                },
                {
                    requiredWithTitle: [
                        {
                            id: 2,
                            title: 'Description',
                            items: ['Projet de recherche et d’innovation sur la génération des QR codes personnalisés. \n',
                                'La solution devra permettre aux entreprises et aux personnes physiques de personnaliser leurs QR codes de façons dynamiques\n'
                            ]
                        },
                        {
                            id: 3,
                            title: 'Environnement technique :\n',
                            items: ['Python', 'qrcode.js', 'Google chart API']
                        }
                    ]
                }
            ],
        },
        {
            id: 4,
            type: t('intern.typeTravail'),
            titre: 'PFE QR Code – Deep Tech\n',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 01/02/2023',
            divBloc: [
                {
                    title: '',
                    text: 'Nous recherchons deux projets de fin d\'étude Fukk stack pour travailler sur des projets dans les domaines de la technologie du sport et de la technologie de la santé. Les deux projets sont orientés services et basés sur des fonctions lambda et un déploiement sur AWS ou Microsoft Azure.\n' +
                        '\n'

                },
                {
                    requiredWithTitle: [
                        {
                            id: 2,
                            title: 'Description',
                            items: ['Projet de recherche et d’innovation sur l’intégration immersive avec les plateformes e-meeting\n']
                        },
                        {
                            id: 3,
                            title: 'Environnement technique :\n',
                            items: ['C++', '.Net Core', 'Microsoft Office 365']
                        }
                    ]
                }
            ],
        },
        {
            id: 4,
            type: t('intern.typeTravail'),
            titre: 'PFE E-meeting platforms – Deep Tech\n',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 01/02/2023',
            divBloc: [
                {
                    title: '',
                    text: 'Nous recherchons deux projets de fin d\'étude Fukk stack pour travailler sur des projets dans les domaines de la technologie du sport et de la technologie de la santé. Les deux projets sont orientés services et basés sur des fonctions lambda et un déploiement sur AWS ou Microsoft Azure.\n' +
                        '\n'

                },
                {
                    requiredWithTitle: [
                        {
                            id: 2,
                            title: 'Description',
                            items: ['Projet de recherche et d’innovation sur l’intégration immersive avec les plateformes e-meeting\n']
                        },
                        {
                            id: 3,
                            title: 'Environnement technique :\n',
                            items: ['C++', '.Net Core', 'Microsoft Office 365']
                        }
                    ]
                }
            ],
        },

    ]
    const normFile = (e:any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <div className="contact"
             style={{
                 height: 'auto',
                 backgroundImage: 'unset',
                 padding: window.innerWidth > 768 ? '9.75vh 17px 5px 43px' : '10vh 10px',
                 display: 'flex',
                 flexDirection: 'column',
             }}

        >

            <div style={{position: 'relative', zIndex: '999'}} id="title-client">
                <TitleSection section={'intern'} title={t('intern.title')} firstTitle={''}/>
            </div>
            <div>
                <p style={{
                    fontSize: window.innerWidth > 768 ? '1vw' : '3.5vw',
                    margin: 'unset',
                    position: 'relative',
                    zIndex: '99'
                }} id={'p'}>
                    {t('intern.description')}
                </p>
            </div>
            <div className={'jobs'}>
                <div className={'job_container'}>
                    <div className="card-contact2">
                        {
                            Job.map((item: any, index: any) => (
                                <CardItem key={index} props={item}/>
                            ))

                        }
                    </div>
                    <div className={'Candidature'}>
                        <h2 style={{padding: '0 20px'}}>{t('contact.candidature')}</h2>
                        <div className={'form'}>
                            <Form
                                form={form}
                                name="basic"
                                labelCol={{span: 8}}
                                wrapperCol={{span: 16}}
                                style={{width: '100%'}}
                                initialValues={{remember: true}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className={'form_container'}>
                                    <div>
                                        <Form.Item
                                            name="nom"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <input type={'text'} className="input__field" placeholder=" "/>
                                                <span className="input__label">{t('contact.nom')}</span>
                                            </label>
                                        </Form.Item>
                                        <Form.Item
                                            name="Message"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <textarea style={{resize:'none'}} rows={6} maxLength={3000} className="input__field"
                                                          placeholder=" "/>
                                                <span className="input__label">{t('contact.message')}</span>
                                            </label>
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <Form.Item
                                            name="E_mail"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <input type={'text'} className="input__field" placeholder=" "/>
                                                <span className="input__label">{t('contact.email')}</span>
                                            </label>
                                        </Form.Item>
                                        <Form.Item
                                            name="file"
                                            getValueFromEvent={normFile}

                                        >
                                            <Upload multiple={false}    action="/upload.do"  listType="picture-card">
                                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                                    <PlusOutlined/>
                                                    <div>{t('contact.download')}</div>
                                                </div>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <Form.Item style={{
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                        width: window.innerWidth > 768 ? '10%' : '30%',
                                        position: 'relative'
                                    }}>
                                        <Button style={{
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            backgroundColor: '#4981be',
                                            position: 'relative'
                                        }} type="primary"
                                                htmlType="submit">
                                            {t('contact.send')}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>


                    </div>
                </div>
                <div className={'job_container-left'}>
                    <img src={stage} alt={'image'}/>
                </div>
            </div>

        </div>
    );
};

export default Intern;
