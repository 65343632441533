import React from "react"
import '../maincomponent/mainComponent.css'
import ItemContact from "../contactCard/ContactCardItem";
import './contactSection.scss'
import '../../assets/i18n'
import {useTranslation} from "react-i18next";
import internCover from '../../assets/img/internCover.png'
import jobCover from '../../assets/img/jobCover.png'
import image1 from "../../assets/img/Site_home.png";


const ContactSection=()=>{
    const {t}=useTranslation()
    const dataContact = [
        {
            img: internCover,
            title:t('contactSection.card1_title'),
            btnText: 'test',
            To:'/internship'
        },  {
            img: jobCover,
            title:t('contactSection.card2_title'),
            btnText: 'test',
            To:'/jobs'
        },

    ]

    return(
                <div className="center-vertically">
                    <div className="items-contact ">
                        {
                            dataContact.map((item:any) => {
                                return (
                                    <ItemContact to={item.To} img={item.img} title={item.title} btnTxt={item.btnText}/>
                                )
                            })
                        }
                    </div>

                </div>
    )
}
export default ContactSection
