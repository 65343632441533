import React from "react";
import mobile from "../../assets/img/mobile.png";
import web from "../../assets/img/web-dev.png";
import consulting from "../../assets/img/consulting.png";
import '../maincomponent/mainComponent.css'
import TitleSection from "../titleSection/TitlteSection";
import '../../assets/i18n'
import {useTranslation} from "react-i18next";
import react from '../../assets/img/react.png'
import angular from '../../assets/img/angular.png'
import android from '../../assets/img/android.jpg'
import flutter from '../../assets/img/flutter.png'
import node from '../../assets/img/nodeJs.jpg'
import c from '../../assets/img/c.jpg'
import python from '../../assets/img/python.png'
import laravel from '../../assets/img/laravel.png'
import azure from '../../assets/img/azure.png'
import aws from '../../assets/img/aws.png'
import ios from '../../assets/img/ios.jpeg'
import ui_ux from '../../assets/img/ui_ux.jpg'
import documentation from '../../assets/img/documentation.png'
import test from '../../assets/img/test.png'
import projet from '../../assets/img/gestion-de-projet (1).png'
import i18n from "i18next";

const ServiceSections = () => {


    const {t} = useTranslation()
    return (
        <section id={'expertise'} className={'section'} style={{
            paddingTop: window.innerWidth > 768 ? '9vh' : '0vh',
            height: window.innerWidth > 768 ? '100vh' : ''
        }}>
            <div id={'first'} style={{display: "unset"}} className="container reveal fade-right">
                <div style={{padding: window.innerWidth > 768 ? '' : '20px'}} className="title">
                    <div id="title-client">
                        <TitleSection section={'expertise'} title={t('serviceSection.title')} firstTitle={''}/>
                    </div>
                    <p style={{
                        textAlign: 'justify', margin: 'unset', maxWidth: 'unset', position: 'relative', left: '5vw'
                        , width: window.innerWidth > 786 ? '90%' : ''
                    }}
                       id={'p'}>{t('serviceSection.title3')}</p>
                </div>
                <div className={'card-container'}>
                    <div className={'card'}>
                        <img className={'logoService'} src={mobile} alt={'mobile'}/>
                        <div className={'card-details'}>
                            <h2 className={'card-title'}>
                                {t('serviceSection.card1_title')}</h2>
                            <p className={'detail-card-inspire service'}> {t('serviceSection.card1_desc')}</p>
                            <div className={'bloc_tech'}>
                                <img title={'Ios'} className={'tech-image'} src={ios}/>
                                <img  title={'Android'} className={'tech-image'} src={android}/>
                                <img title={'Flutter'} className={'tech-image'} src={flutter}/>
                            </div>
                        </div>
                    </div>
                    <div className={'card'}>
                        <img className={'logoService'} src={web} alt={'web'}/>
                        <div className={'card-details'}>
                            <h2 className={'card-title'}> {t('serviceSection.card2_title')}</h2>
                            <p className={'detail-card-inspire service'}> {t('serviceSection.card2_desc')}</p>
                            <div className={'bloc_tech-front'}>
                                <img title={'ReactJs'} className={'tech-image'} src={react}/>
                                <img style={{position:'relative',right:'3.5px'}} title={'Angular'} className={'tech-image'} src={angular}/>
                                <img title={'C#'} className={'tech-image'} src={c}/>
                                <img title={'Python'} className={'tech-image'} src={python}/>
                                <img title={'NodeJs'} className={'tech-image'} src={'https://www.vectorlogo.zone/logos/nodejs/nodejs-ar21.png'}/>
                                <img title={'Laravel'} className={'tech-image'} src={laravel}/>
                            </div>

                        </div>
                    </div>

                    <div className={'card'}>
                        <img className={'logoService'} src={consulting} alt={'consult'}/>
                        <div className={'card-details'}>
                            <h2 className={'card-title'}> {t('serviceSection.card3_title')} </h2>
                            <p className={'detail-card-inspire service'}> {t('serviceSection.card3_desc')} </p>
                            <div className={'bloc_tech-front'}>
                                <img title={'Aws'} className={'tech-image'} src={aws}/>
                                <img title={'Azure'} className={'tech-image'} src={azure}/>
                                <img title={'UI/UX'} className={'tech-image'} src={ui_ux}/>
                                <img title={t('serviceSection.hover1') ?? ''} className={'tech-image'} src={documentation}/>
                                <img title={t('serviceSection.hover2') ?? ''} className={'tech-image'} src={test}/>
                                <img title={t('serviceSection.hover3') ?? ''} className={'tech-image'} src={projet}/>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
export default ServiceSections
