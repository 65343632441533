import React, {useEffect, useRef, useState} from "react";
import "./Career.scss";
import '../../components/maincomponent/mainComponent.css'
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import "../../assets/i18n";
import CardItem from "./Card";
import {Button, Form, Input, Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import emploi from '../../assets/img/emploi.png'
import TitleSection from "../../components/titleSection/TitlteSection";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const Jobs = () => {

    const [form] = Form.useForm();
    const {t, i18n} = useTranslation();

    const onFinish = (values: any) => {

        emailjs.send("service_m458bcl", "template_j09pxrk", {
            from_name: values.nom,
            email: values.Email,
            to_name: "bizdev@grantstech.io",
            message: values.Message,
        }, 'RpNGhZI3_xLrE0dHL').then(() => {
            setTimeout(() => {
                Swal.fire(
                    'Success!',
                    'votre mail a été envoyé avec succès!',
                    'success'
                )
                form.resetFields();
            }, 500)


        }).catch(() => {
            setTimeout(() => {
                Swal.fire(
                    'Error!',
                    'votre mail n\'a pas pu être envoyé!',
                    'error'
                )
                form.resetFields();
            }, 500)

        });

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const dataContact = [
        {
            id: 1,
            img: 'https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&w=1600',
            title: 'Welcome with us',
            text: '',
        },

    ];
    const Job = [
        {

            id: 1,
            type: t('intern.typeTravail'),
            titre: 'Développeur Full-Stack',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 25/04/2021',
            divBloc: [
                {
                    title: '',
                    text: 'Nous recherchons un développeur junior Full-Stack pour rejoindre notre équipe d\'ingénieurs. Ce poste aura pour objectif de nous aider à exécuter rapidement notre feuille de route API et à faire correspondre les besoins métiers avec le front-end. Ce poste sera en télétravail. Il s\'agit d\'une opportunité de rejoindre une entreprise de mentorat de start-ups à croissance rapide dans un marché en pleine expansion.\n' +
                        '\n ',
                },
                {
                    title: 'Compétences demandées :\n',
                    text: '',
                    requiredWithoutTitle: ['Autonomie : depuis la conception technique, le développement, les tests, le déploiement, la maintenance et la documentation\n',
                        'Collaborer avec l\'équipe produit et d\'autres membres de l\'équipe d\'ingénierie pour satisfaire les défis commerciaux et techniques de manière simple et durable\n',
                    ],
                    requiredWithTitle: [
                        {
                            id: 1,
                            title: 'Maitrise de :\n',
                            items: ['- NodeJs', '- ReactJs', '- Angular', '- Lambda functions']
                        },
                        {
                            id: 1,
                            title: 'Un plus serait de maitriser :\n',
                            items: ['           - React/Redux, RabbitMQ, JSON API, Nginx, Supervisord, Couchbase, Docker\n' +
                            '\n',
                                '           - Mécanismes d\'authentification tels que : OAuth, JWT, etc.\n' +
                                '\n',
                                '           - Browser automation & web scraping\n' +
                                '\n',
                                '           - Systèmes distribués et mise en réseau\n' +
                                '\n']
                        }
                    ]
                }
            ],
        },
        {
            id: 2,
            type: t('intern.typeTravail'),
            titre: 'Développeur Mobile',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 05/06/2021',
            divBloc: [
                {
                    title: 'Responsibilities :\n',
                    text: 'Within an agile team, you will be in charge of analysing, designing, programming, maintaining, building, deploying and evolving cross-platform mobile solutions.\n' +
                        '\n' +
                        'Being part of the R&D team, you will participate in the decision-making process to design cutting-edge mobile applications with modern technologies and architectures which require strong analytical and development skills\n' +
                        '\n' +
                        'If you have an experience between 1 and 3 years, you are responsible, passionate, committed and work with great enthusiasm, Grant’s Tech gives you the opportunity to develop skills and build a solid career in a dynamic working environment.',
                },
                {
                    title: 'Your mission :\n',
                    requiredWithoutTitle: ['Write technical specification while closely working with the technical and business experts teams.\n' +
                    '\n', 'Mobile cross-platform programming under Flutter.\n' +
                    '\n',
                        'Implement and maintain modern architecture such as MVVM-cross.\n' +
                        '\n',
                        'Issues analysis and fixing.\n' +
                        '\n',
                        'Mobile application maintenance and improvement.\n' +
                        '\n',
                        'Perform unitary tests.\n' +
                        '\n',
                        'Build and delivery of deployment packages for different platforms (Android, iOS, Huawei).\n' +
                        '\n'
                    ],
                },
                {
                    title: 'Required skills and qualifications  :\n',
                    requiredWithoutTitle: ['Software and computer science degree.\n',
                        'Tools: Visual Studio, Android Studio, Github, GitScrum.\n',
                        'Design patterns, programming languages and technologies: Flutter, Web API, MVVM, NodeJS, .NET Core, micro services, managed services.\n'
                    ],
                }
            ],

        },
        {

            id: 3,
            type: t('intern.typeTravail'),
            titre: 'Ingénieur DevOps',
            level: 'Ingénieur en informatioque',
            date: 'publiée le 05/06/2021',
            divBloc: [

                {
                    title: 'Responsibilities :\n',
                    text: '',
                    requiredWithoutTitle: ['Analyze current technology utilized within the company and develop steps and processes to improve and expand upon them\n' +
                    '\n',
                        'Establish milestones for necessary contributions from departments and develop processes to facilitate their collaboration\n' +
                        '\n',
                        'Assist other department engineers in creating practical demonstrations of proposed solutions and demonstrating them to other members of the team\n' +
                        '\n',
                        'Provide detailed specifications for proposed solutions including materials, manpower and time necessary\n' +
                        '\n',
                        'Provide clear goals for all areas of a project and develop steps to oversee their timely execution\n' +
                        '\n',
                        'Work closely with engineering professionals within the company to maintain hardware and software needed for projects to be completed efficiently\n' +
                        '\n',
                        'Mentor and train other engineers throughout the company and seek to continually improve processes companywide\n' +
                        '\n',
                        'Work alongside project management teams to successfully monitor progress and implementation of initiatives\n' +
                        '\n'
                        ,]
                    ,
                },
                {
                    title: 'Required skills and qualifications  :\n',
                    text: '',
                    requiredWithoutTitle: ['Experience developing engineering applications for a large corporation.\n' +
                    '\n',
                        'Master\'s degree in Computer Science or Software Engineering is preferred.\n' +
                        '\n',
                        'Current understanding of best practices regarding system security measures.\n' +
                        '\n',
                        'Experience with software engineering and customer experience.\n' +
                        '\n']
                    ,
                }
            ],
        },
    ]
    const normFile = (e:any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <div className="contact" style={{
            height: 'auto',
            backgroundImage: 'unset',
            padding: window.innerWidth > 768 ? '9.75vh 17px 5px 43px' : '10vh 10px',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{position: 'relative', zIndex: '999'}} id="title-client">
                <TitleSection section={''} title={t('intern.title')} firstTitle={''}/>
            </div>

            <div>
                <p style={{
                    fontSize: window.innerWidth > 768 ? '1vw' : '3.5vw',
                    margin: 'unset',
                    position: 'relative',
                    zIndex: '99'
                }} id={'p'}>
                    {t('intern.description')}
                </p>
            </div>
            <div className={'jobs'}>
                <div className={'job_container'}>
                    <div className="card-contact2">
                        {
                            Job?.map((item: any, index: any) => (
                                <CardItem key={index} props={item}/>
                            ))

                        }
                    </div>
                    <div className={'Candidature'}>
                        <h2 style={{padding: '0 20px'}}>{t('contact.candidature')}</h2>
                        <div className={'form'}>
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{span: 8}}
                                wrapperCol={{span: 16}}
                                style={{width: '100%'}}
                                initialValues={{remember: true}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className={'form_container'}>
                                    <div>
                                        <Form.Item
                                            name="nom"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <input type={'text'} className="input__field" placeholder=" "/>
                                                <span className="input__label">{t('contact.nom')}</span>
                                            </label>
                                        </Form.Item>
                                        <Form.Item
                                            name="Message"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <textarea style={{resize:'none'}} rows={6} maxLength={3000} className="input__field"
                                                          placeholder=" "/>
                                                <span className="input__label">{t('contact.message')}</span>
                                            </label>
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <Form.Item
                                            name="Email"
                                            rules={[{required: true, message: t('contact.erreur') ?? ''}]}
                                        >
                                            <label className="input">
                                                <input type={'text'} className="input__field" placeholder=" "/>
                                                <span className="input__label">{t('contact.email')}</span>
                                            </label>
                                        </Form.Item>
                                        <Form.Item
                                            name="file"
                                            getValueFromEvent={normFile}
                                            >
                                            <Upload multiple={false} action="/upload.do" listType="picture-card">
                                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                                    <PlusOutlined/>
                                                    <div>{t('contact.download')}</div>
                                                </div>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <Form.Item style={{
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                        width: window.innerWidth > 768 ? '10%' : '30%',
                                        position: 'relative'
                                    }}>
                                        <Button style={{
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            backgroundColor: '#4981be',
                                            position: 'relative'
                                        }} type="primary"
                                                htmlType="submit">
                                            {t('contact.send')}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>


                    </div>
                </div>
                <div className={'job_container-left'}>
                    <img src={emploi} alt={'image'}/>
                </div>
            </div>

        </div>
    );
};

export default Jobs;
