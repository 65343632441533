import React, {useEffect} from "react";
import image1 from "../../assets/img/Site_home.png";
import homeEn from "../../assets/img/Site home EN.png";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import project from "../../assets/img/projet.221a5aaa39e1b7a9d6465cb2ed5abca8.png";
import client from "../../assets/img/client.2b918de64b84a7275ef9fb3d71f3401a.png";
import employee from '../../assets/img/employee.08accf9b047033a9c2f41b90f0a18680.png'
import './../maincomponent/mainComponent.css'
import '../../assets/i18n'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";

const FirstSecteur = () => {
    const {t} = useTranslation()
    useEffect(() => {
        const reveals = document.querySelectorAll(".reveal");
        reveals[0].classList.add("active");
    }, [])
    const navigate = useNavigate()

    return (
        <section id={'home'} className={'section'} style={{paddingTop: '15vh', top:'60px',padding: '20px'}}>
            <div  id={'first'}
                 className="container reveal fade-bottom">
                <div className="left-sect">
                    <div style={{
                        position: 'relative',
                        left: window.innerWidth > 768 ? '3vw' : ""
                    }}
                         className="title">
                        <h1 className="title-1">{t('firstSecteur.title1')}</h1><h1
                        className="title-2">{t('firstSecteur.title2')}</h1></div>
                    <div className="descript"
                         style={{
                             position: 'relative',
                             left: window.innerWidth > 768 ? '3vw' : ""
                         }}
                    >
                        <p style={{textAlign: 'justify', maxWidth: window.innerWidth > 768 ? '47vw' : "unset"}}
                           id={'p'}>
                            {t('firstSecteur.paragraphe')}</p></div>
                    <button onClick={() => {
                        navigate('/Contacts')
                    }} className="primary-btn">{t('firstSecteur.nvProjet')}</button>
                </div>
                <img className={'image1'} src={i18n.language==='fr' ? image1 : homeEn} alt={'count'}/>
            </div>
            <div className={'countContainer'}
                 style={{display: 'flex', width: '100%', justifyContent: 'space-evenly'}}>
                <div className={'divCount'}>
                    <img alt={'logo'} className={'logoCount'} src={client}/>
                    <VisibilitySensor partialVisibility offset={{bottom: 0}}>
                        {({isVisible}: any) => (
                            <div className={'divCountDetails'}>
                                <div>
                                    {
                                        isVisible && (
                                            <span className={'countPlus'}>
                                                  +
                                              </span>
                                        )
                                    }
                                    {isVisible ? <CountUp className={'count'} end={16}/> : null}
                                </div>
                                <span className={'titleCount'}> {t('firstSecteur.experience')}</span>
                            </div>
                        )}
                    </VisibilitySensor>
                </div>
                <div className={'divCount'}>
                    <img alt={'logo'} className={'logoCount'} src={project}/>
                    <VisibilitySensor partialVisibility offset={{bottom: 0}}>
                        {({isVisible}: any) => (
                            <div className={'divCountDetails'}>
                                <div>
                                    {
                                        isVisible && (
                                            <span className={'countPlus'}>
                                                  +
                                              </span>
                                        )
                                    }
                                    {isVisible ? <CountUp className={'count'} end={15}/> : null}
                                </div>
                                <span className={'titleCount'}> {t('firstSecteur.projet')}</span>
                            </div>
                        )}
                    </VisibilitySensor>
                </div>
                <div className={'divCount'}>
                    <img alt={'logo'} className={'logoCount'} src={employee}/>
                    <VisibilitySensor partialVisibility offset={{bottom: 0}}>
                        {({isVisible}: any) => (
                            <div className={'divCountDetails'}>
                                <div>
                                    {
                                        isVisible && (
                                            <span className={'countPlus'}>
                                                  +
                                              </span>
                                        )
                                    }
                                    {isVisible ? <CountUp className={'count'} end={10}/> : null}
                                </div>
                                <span className={'titleCount'}> {t('firstSecteur.client')}</span>
                            </div>
                        )}
                    </VisibilitySensor>
                </div>

            </div>

        </section>
    )
}
export default FirstSecteur
