import React, {useEffect, useState} from "react";
import logo from './../../assets/img/grants_logo.png'
import './navbar.scss'
import '../../assets/i18n';

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Select} from "antd";
import fabars from '../../assets/img/fabars.png'
import close from '../../assets/img/close.jpg'
import i18next from "i18next";

const NavBar = () => {
    const {t, i18n} = useTranslation();
    const [active, setActive] = useState('home')
    const navigate = useNavigate()
    const [clicked,setClicked]=useState<any>(false)

    function myFunction() {
        let x = document.getElementById("myTopnav");
        setClicked(!clicked)
        if (x) {
            if (x?.className === "topnav") {
                x.className += " responsive";
            } else {
                x.className = "topnav";
            }
        }
    }

    function myFunction2() {
       setClicked(!clicked)
    }

    const change = (event: any) => {
        if (event === "fr") {
            i18n.changeLanguage("fr");
            localStorage.setItem('language','fr')
        } else {
            i18n.changeLanguage("en");
            localStorage.setItem('language','en')

        }
    }



    useEffect(() => {
        const releventDiv = document.getElementById(active);
        if (releventDiv) {
            releventDiv.scrollIntoView({behavior: "smooth"});

          if ( window.innerWidth < 760 ){
              window.scroll({
                  top:releventDiv.offsetTop - 80,
                  behavior:'smooth'
              })
          }else{
                 if (i18next.language==='fr'){
                     window.scroll({
                         top:releventDiv.offsetTop - 50,
                         behavior:'smooth'
                     })
                 }else{
                     window.scroll({
                         top:releventDiv.offsetTop - 25,
                         behavior:'smooth'
                     })
                 }
          }


        }


    }, [active])
    const goToSection = (id: any) => {
        if ((window.location.pathname === '/career') || (window.location.pathname === '/Contacts') ||
            (window.location.pathname === '/internship') || (window.location.pathname === '/jobs')|| (window.location.pathname.includes('/opportunity'))) {
            setActive(id)
            navigate('/')

        }

        const releventDiv = document.getElementById(id);
        if (releventDiv) {
            setActive(id)
            releventDiv.scrollIntoView({behavior: "smooth"});
        }

    }
    const [open, setOpen] = useState(false)
    const refresh=()=>{
        if (window.location.pathname ==='/'){
            window.location.reload()
        }else
        {
            navigate('/')

        }

    }
    return (
        <div className="topnav" id="myTopnav">
            <img style={{cursor:'pointer'}} onClick={refresh} src={logo} alt={'GRANTSTECH'}/>
            <div className={'items'}>
                <a href="/"
                   className={window.location.pathname === '/' && active === 'home' ? 'true' : 'false'}> {t('header.home')}</a>
                <a style={{cursor: 'pointer'}} onClick={() => {
                    goToSection('propos')
                }} className={`${active === 'propos'}`}>{t('header.aboutUs')}</a>
                <a style={{cursor: 'pointer'}} onClick={() => {
                    goToSection('expertise')
                }} className={`${active === 'expertise'}`}>{t('header.expertise')}</a>
                <a style={{cursor: 'pointer'}} onClick={() => {
                    goToSection('valeur')
                }} className={`${active === 'valeur'}`}>{t('header.nosValeur')}</a>
                <a style={{cursor: 'pointer'}} onClick={() => {
                    goToSection('mission')
                }} className={`${active === 'mission'}`}>{t('header.notreMission')}</a>
                <a href="/Contacts"
                   className={window.location.pathname === '/Contacts' ? 'true' : ''}>{t('header.Contact')}</a>
                <a href="/career"
                   className={window.location.pathname === '/career' || window.location.pathname === '/jobs' || window.location.pathname === '/internship' ? 'true' : ''}>{t('header.Carierre')}</a>
                <a id={'lng'}>
                    <Select
                        defaultValue={i18n.language}
                        value={i18n.language === 'fr' ? t('header.french') : t('header.english')}
                        onFocus={() => {
                            setOpen(true)
                        }}
                        onBlur={() => {
                            setOpen(false)
                        }}
                        bordered={false}
                        dropdownStyle={{zIndex: 99999999999}}
                        onChange={change}
                        style={{border: 'unset', fontSize: '0.9vw'}}
                        options={[
                           i18n.language ==='en' ?
                            { value: 'fr', label: t('header.french') }:
                            { value:  'en', label: t('header.english') },
                        ]}
                    />

                </a>
                <a href="javascript:void(0);" className="icon" onClick={myFunction}>
                    {!clicked ?( <img className={'fa-bars'}
                                      src={fabars}/>

                    ) : (<img onClick={myFunction2} className={'fa-bars-close'}
                              src={close}/>) }

                </a>
            </div>


        </div>
    )
}
export default NavBar
