import React, {useEffect} from "react";
import '../../components/maincomponent/mainComponent.css'
import {useTranslation} from "react-i18next";
import '../../assets/i18n'
import AboutUsCard from "./aboutUsCard";
import AboutUsSection from "../../components/aboutUsSection/AboutUsSection";
import ValeurSection from "../../components/valeurSection/ValeurSection";
import MissionSection from "../../components/missionSection/MissionSection";
const AboutUs=()=>{
    useEffect(() => {
        const reveals = document.querySelectorAll(".reveal");
        reveals[0].classList.add("active");
    }, [])
    const valeur=[
        {
            id:1,
            titre:'Gestion des talents',
            details:'Chaque collaborateur est unique dans notre entreprise. Attirer, développer et retenir les meilleurs talents sont nos atouts.\n' +
                '\n' +
                'Faire preuve d’une attitude positive et favoriser un environnement collaboratif et solidaire sont nos priorités.',
            img:'http://www.grantstech.io/gallery_gen/c6dd00104367169c4725a0b393b27dea.jpg'
        },
        {
            id:1,
            titre:'Gestion des talents',
            details:'Chaque collaborateur est unique dans notre entreprise. Attirer, développer et retenir les meilleurs talents sont nos atouts.\n' +
                '\n' +
                'Faire preuve d’une attitude positive et favoriser un environnement collaboratif et solidaire sont nos priorités.',
            img:'http://www.grantstech.io/gallery_gen/c6dd00104367169c4725a0b393b27dea.jpg'
        },
        {
            id:1,
            titre:'Gestion des talents',
            details:'Chaque collaborateur est unique dans notre entreprise. Attirer, développer et retenir les meilleurs talents sont nos atouts.\n' +
                '\n' +
                'Faire preuve d’une attitude positive et favoriser un environnement collaboratif et solidaire sont nos priorités.',
            img:'http://www.grantstech.io/gallery_gen/c6dd00104367169c4725a0b393b27dea.jpg'
        },
        {
            id:1,
            titre:'Gestion des talents',
            details:'Chaque collaborateur est unique dans notre entreprise. Attirer, développer et retenir les meilleurs talents sont nos atouts.\n' +
                '\n' +
                'Faire preuve d’une attitude positive et favoriser un environnement collaboratif et solidaire sont nos priorités.',
            img:'http://www.grantstech.io/gallery_gen/c6dd00104367169c4725a0b393b27dea.jpg'
        },
    ]

    function reveal() {
        const reveals = document.querySelectorAll(".reveal");

        for (let i = 0; i < reveals.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = reveals[i].getBoundingClientRect().top;
            const elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);
    const {t}=useTranslation()
    return(
        <div>
            <MissionSection/>
        </div>

    )
}
export default AboutUs
